import { CeresBusinessPartnerConfig } from "./interfaces/ceres-business-partner-config.interface";

const BUSINESSPARTNERCONFIG: CeresBusinessPartnerConfig = {
    'business-partner.salutation': true,
    'business-partner.title': true,
    'business-partner.subject-area': true,
    'business-partner.function': true,
    'business-partner.budget-responsibility': true,
    'business-partner.language': true,
    'business-partner.status': true,
    'business-partner.phone': true,
    'business-partner.fax': true,
    'business-partner.po-box': true,
    'business-partner.country': true,
    'business-partner.newsletter-candidate': true,
    'business-partner.newsletter-blocking': true,
    'business-partner.focus': true,
    'business-partner.ad-blocking': true,
    'business-partner.checked-marked-for-deletion': true,
    'business-partner.christmas-card': true,
}

export default BUSINESSPARTNERCONFIG;
