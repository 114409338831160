import { Injectable } from "@angular/core";
import { FeatureTogglesConfigLoaderService } from "@ceres/feature-toggles";
import { CeresFeaturesConfig } from "../../../features/interfaces/ceres-features-config.interface";
import { Observable, of } from "rxjs";
import FEATURES_CONFIG from "../../../features/features";

@Injectable()
export class StaticFeaturesAdapterService extends FeatureTogglesConfigLoaderService<CeresFeaturesConfig> {
  private config = of(FEATURES_CONFIG)
  loadFeatureConfig(): Observable<CeresFeaturesConfig> {
    return this.config;
  }
}
