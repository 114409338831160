export const environment = {
  production: true,
  edgeService: 'https://api.dilearning.ceres.siemens.cloud',
  scd_url: 'https://api.scd.suites.siemens.cloud',
  siteCollection: '/content/10000120',
  languageAssetsPath: './assets/language/',
  tracking: true,
  whitelist: ['localhost:3000', 'api.dilearning.ceres.siemens.cloud'],
  authentication: {
    unauthorizedRoute: '/unauthorized',
    redirectUrl: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    clientId: '341pihds8uvp5idaa4s8e83cs0',
    authority:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM',
    authWellknownEndpointUrl:
      'https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_tVz7DaEVM/.well-known/openid-configuration',
    scope: 'openid profile email',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    autoUserInfo: true,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    renewUserInfoAfterTokenRenew: true,
    ignoreNonceAfterRefresh: true
  },
  apiKey: 'JYdsS9A5is4qaJVXbO5Tp85itCNlIJAJ4537I45f',
  tenant: 'DI CS FA DIL',
  guestAccess: false,
  links: {
    documentOfferTemplate: '',
    documentOfferTemplateEN: ''
  },
  junoApp: 'https://juno.suites.siemens.cloud',
  junoApiURI: 'https://api.mail.wcs-apps.siemens.cloud/v2.0',
  junoAppId: '73a1c4a89ae54505824b6f0f4fcc78b3'
};
